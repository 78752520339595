import { UIIcon, UIIconColorProps, getIconColorSVGFill } from 'components/utils'

export const NoteCreditIcon = ({
  color,
  size = 24,
}: UIIcon & UIIconColorProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 41 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.5'
        width='40'
        height='40'
        rx='20'
        fill='var(--surfacePositiveInitial)'
      />
      <path
        d='M26.5 21H21.5V26C21.5 26.55 21.05 27 20.5 27C19.95 27 19.5 26.55 19.5 26V21H14.5C13.95 21 13.5 20.55 13.5 20C13.5 19.45 13.95 19 14.5 19H19.5V14C19.5 13.45 19.95 13 20.5 13C21.05 13 21.5 13.45 21.5 14V19H26.5C27.05 19 27.5 19.45 27.5 20C27.5 20.55 27.05 21 26.5 21Z'
        fill={color ? getIconColorSVGFill(color) : 'currentColor'}
      />
    </svg>
  )
}
